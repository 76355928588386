.articles-cards{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
}

.ArticleCard_item {
    display: flex;
    padding: 1rem;
    box-shadow: 0 1px 8px 12px rgba(104, 174, 167, 0.2), 0 6px 1px 1px rgba(139, 137, 65, 0.19);
}



.ArticleCard {

    border-radius: 20px;
    overflow: hidden;
    max-width: 30ch;
    background-color: #90a18961;
    max-height: auto;
    transition : transform 500ms ease;
    flex-direction: column;
    &:hover{
        transform:scale(1.1);
        .ArticleCardContent-title::after {
            transform:(scaleX(1));
        }
    }
    

}

.ArticleCardContent {
    padding : 1.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;


    &-title {
        position: relative;
        width: max-content;
        max-width: 100% ;
    
    }
    &-title::after {
        content : "";
        position : absolute;
        height: 3px;
        left: 0;
        bottom: 0;
        width: 100%;  
        background: yellow;
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 500ms ease;
    }

    &-button {
        &:hover {
            background-color: $color-theme;
            color : white;
        }
        cursor: pointer;
        display: inline-block;
        text-decoration: none;
        color : $color-theme;
        padding : .5em 1.25em;
        border-radius: 2em;

    }

    &-description {
        padding-top: 2em;
        padding-bottom: 1em;
    }

    &-tags {
        padding-bottom: 1em;
        display: inline-block

    }
}

.ArticleImageContainer {
    
    background-size:cover ;
    background-position: center;;
    width: 100%;
    height: 200px; 
}

.cut-text { 
    text-overflow: ellipsis;
    overflow: hidden; 
    width: 300px; 
    white-space: nowrap;
}

.skillTag{
    background-color:$color-theme;
    border-radius:30px;
    border-color:white;
    border-width: 1.3px;
    border-style: solid;
    font-weight:bold;
    color: white;
    box-shadow:-2px -2px 1px 3px $color-theme inset,5px 5px 5px rgba(255, 255, 255,0.5) inset;
    margin-left: 10px;
    font-size: 13px;
    margin-bottom: 5px;
    width: 39%;
    text-align: center;

}

