@import url(https://fonts.googleapis.com/css2?family=Special+Elite&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/**
 * Reset
 */
html {
  color: #222;
  font-size: 1em;
  line-height: 1.4;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.hidden {
  display: none !important;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
  white-space: inherit;
}

.invisible {
  visibility: hidden;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

@media only screen and (min-width: 35em) {
  /* Style adjustments for viewports that meet the condition */
}
@media print, (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 1.25dppx), (min-resolution: 120dpi) {
  /* Style adjustments for high resolution devices */
}
@media print {
  *,
*:before,
*:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after,
a[href^="javascript:"]:after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }
}
/**
 * Typography
 */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #383838;
}

*::-webkit-scrollbar {
  width: 8px;
  background-color: #383838;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #6b6b6b;
}

html {
  overflow: hidden;
  overflow-y: auto;
}

body {
  overflow: hidden !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  line-height: 1.8rem;
  font-family: "Special Elite", cursive;
  color: #a4acc4;
  font-weight: 400;
  background: rgb(19, 19, 19);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  body {
    font-size: 16px;
  }
}
@media only screen and (max-width: 767px) {
  body {
    font-size: 15px;
  }
}

img {
  max-width: 100%;
}

::-webkit-input-placeholder {
  color: #a4acc4;
}

:-moz-placeholder {
  color: #a4acc4;
}

::-moz-placeholder {
  color: #a4acc4;
}

:-ms-input-placeholder {
  color: #a4acc4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Special Elite", cursive;
  color: #ffffff;
}

h1 {
  font-size: 3.2857rem;
  line-height: 4rem;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  h1 {
    font-size: 2.9857rem;
    line-height: 3.4rem;
  }
}
@media only screen and (max-width: 767px) {
  h1 {
    font-size: 2.6857rem;
    line-height: 3.1rem;
  }
}

h2 {
  font-size: 2.57rem;
  line-height: 3.2857rem;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  h2 {
    font-size: 2.37rem;
    line-height: 3.0857rem;
  }
}
@media only screen and (max-width: 767px) {
  h2 {
    font-size: 2.17rem;
    line-height: 2.857rem;
  }
}

h3 {
  font-size: 2rem;
  line-height: 2.7rem;
}
@media only screen and (max-width: 767px) {
  h3 {
    font-size: 1.91rem;
    line-height: 2.357rem;
  }
}

h4 {
  font-size: 1.71rem;
  line-height: 2.43rem;
}
@media only screen and (max-width: 767px) {
  h4 {
    font-size: 1.51rem;
    line-height: 2.13rem;
  }
}

h5 {
  font-size: 1.43rem;
  line-height: 2.14rem;
}
@media only screen and (max-width: 767px) {
  h5 {
    font-size: 1.23rem;
    line-height: 2.04rem;
  }
}

h6 {
  font-size: 1.14rem;
  line-height: 1.857rem;
}
@media only screen and (max-width: 767px) {
  h6 {
    font-size: 1.04rem;
    line-height: 1.657rem;
  }
}

::-moz-selection {
  background: #ffd343;
  color: #ffffff;
}

::-ms-selection {
  background: #ffd343;
  color: #ffffff;
}

::-o-selection {
  background: #ffd343;
  color: #ffffff;
}

::selection {
  background: #ffd343;
  color: #ffffff;
}

a:hover, a:focus, a:active {
  text-decoration: none;
  outline: none;
}

a,
button {
  cursor: pointer;
}

input,
button,
select,
textarea {
  background: transparent;
  border: 1px solid #2e344e;
  font-family: "Special Elite", cursive;
  color: #a4acc4;
}
input ::-webkit-input-placeholder,
button ::-webkit-input-placeholder,
select ::-webkit-input-placeholder,
textarea ::-webkit-input-placeholder {
  color: #a4acc4;
}
input :-moz-placeholder,
button :-moz-placeholder,
select :-moz-placeholder,
textarea :-moz-placeholder {
  color: #a4acc4;
}
input ::-moz-placeholder,
button ::-moz-placeholder,
select ::-moz-placeholder,
textarea ::-moz-placeholder {
  color: #a4acc4;
}
input :-ms-input-placeholder,
button :-ms-input-placeholder,
select :-ms-input-placeholder,
textarea :-ms-input-placeholder {
  color: #a4acc4;
}
input:focus, input:active,
button:focus,
button:active,
select:focus,
select:active,
textarea:focus,
textarea:active {
  outline: none;
  border-color: #ffd343;
}

input,
select,
textarea {
  width: 100%;
  font-size: 14px;
  vertical-align: middle;
}

input,
select,
button {
  height: 50px;
  padding: 0 15px;
}
@media only screen and (max-width: 767px) {
  input,
select,
button {
    height: 44px;
  }
}

textarea {
  padding: 10px 15px;
}

blockquote {
  border-left: 5px solid #ffd343;
  font-size: 18px;
  line-height: 27px;
  padding: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  margin-left: 25px;
  background: #202020;
}
blockquote p {
  margin-bottom: 0;
  font-style: italic;
  font-size: 1.2rem;
}
blockquote footer {
  font-size: 14px;
}
blockquote footer a {
  color: #ffd343;
}
blockquote footer a:hover {
  color: #ffd343;
}
@media only screen and (max-width: 767px) {
  blockquote {
    font-size: 16px;
    line-height: 25px;
    padding: 20px;
    margin-left: 0;
  }
}

[class^=flaticon-],
[class*=" flaticon-"],
[class^=flaticon-],
[class*=" flaticon-"] {
  line-height: 1;
}
[class^=flaticon-]::before, [class^=flaticon-]::after,
[class*=" flaticon-"]::before,
[class*=" flaticon-"]::after,
[class^=flaticon-]::before,
[class^=flaticon-]::after,
[class*=" flaticon-"]::before,
[class*=" flaticon-"]::after {
  margin-left: 0;
  font-size: inherit;
  vertical-align: middle;
}

.stylish-list {
  padding-left: 0;
}
.stylish-list li {
  list-style: none;
  line-height: 30px;
  position: relative;
  padding-left: 35px;
}
.stylish-list i {
  position: absolute;
  left: 0;
  top: 7px;
  font-size: 16px;
  color: #ffd343;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
  -webkit-transition: background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s;
  transition: background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s;
}

/* Checkbox & Radio Styles */
input[type=checkbox],
input[type=radio] {
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  display: none;
}
input[type=checkbox] ~ label,
input[type=radio] ~ label {
  position: relative;
  padding-left: 24px;
  cursor: pointer;
}
input[type=checkbox] ~ label:before,
input[type=radio] ~ label:before {
  content: "";
  font-family: "Font Awesome 5 free";
  font-weight: 700;
  position: absolute;
  left: 0;
  top: 5px;
  border: 1px solid #2e344e;
  height: 15px;
  width: 15px;
  line-height: 1;
  font-size: 13px;
}
input[type=checkbox]:checked ~ label,
input[type=radio]:checked ~ label {
  color: #ffd343;
}
input[type=checkbox]:checked ~ label:before,
input[type=radio]:checked ~ label:before {
  content: "\f00c";
  color: #ffd343;
  border-color: #ffd343;
}

input[type=radio] ~ label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  border: 2px solid #2e344e;
  border-radius: 100px;
  height: 15px;
  width: 15px;
  margin-top: -8px;
  line-height: 0.5;
  font-size: 28px;
}
input[type=radio]:checked ~ label::before {
  content: "";
  color: #ffd343;
  border-color: #ffd343;
}

/**
 * Utilities
 */
.mi-wrapper {
  padding-left: 300px;
  position: relative;
  min-height: 100vh;
  z-index: 1;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .mi-wrapper {
    padding-left: 260px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mi-wrapper {
    padding-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mi-wrapper {
    padding-left: 0;
  }
}
@media only screen and (max-width: 767px) {
  .mi-wrapper {
    padding-left: 0;
  }
}

.color-theme {
  color: #ffd343;
}

.mt-30-reverse {
  margin-top: -30px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-50-reverse {
  margin-top: -50px;
}

.mt-50 {
  margin-top: 50px;
}

.mi-padding-top {
  padding-top: 120px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mi-padding-top {
    padding-top: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .mi-padding-top {
    padding-top: 80px;
  }
}

.mi-padding-bottom {
  padding-bottom: 120px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mi-padding-bottom {
    padding-bottom: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .mi-padding-bottom {
    padding-bottom: 80px;
  }
}

.mi-section {
  position: relative;
}

.mi-bglines {
  position: absolute;
  left: 300px;
  top: 0;
  height: 100%;
  min-height: 100vh;
  width: calc(100% - 300px);
  z-index: -1;
}
.mi-bglines span {
  width: 1px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 1px;
  min-height: 100vh;
  background: rgba(46, 52, 78, 0.3);
}
.mi-bglines span:nth-child(1) {
  left: 20%;
}
.mi-bglines span:nth-child(2) {
  left: 40%;
}
.mi-bglines span:nth-child(3) {
  left: 60%;
}
.mi-bglines span:nth-child(4) {
  left: 80%;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .mi-bglines {
    left: 260px;
    width: calc(100% - 260px);
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mi-bglines {
    left: 0;
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mi-bglines {
    left: 0;
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .mi-bglines {
    left: 0;
    width: 100%;
  }
}

.size-md[class^=lni-],
.size-lg[class^=lni-],
.size-sm[class^=lni-],
.size-xs[class^=lni-] {
  font-size: inherit !important;
}

.myNameColor {
  color: #FFFC00;
}

/**
 * Button
 */
.mi-button {
  position: relative;
  padding: 0 30px;
  background: #ffd343;
  color: #ffffff;
  border: 0;
  display: inline-block;
  z-index: 1;
  text-transform: uppercase;
  font-size: 0.9rem;
  letter-spacing: 2px;
  height: 50px;
  line-height: 50px;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.mi-button::before {
  content: "";
  position: absolute;
  left: 0;
  top: auto;
  bottom: 0;
  height: 2px;
  width: 100%;
  background: #ffffff;
  z-index: -1;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.mi-button:hover {
  color: #ffffff;
}
.mi-button:hover::before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
@media only screen and (max-width: 767px) {
  .mi-button {
    height: 45px;
    line-height: 45px;
  }
}

.mi-readmore {
  position: relative;
  color: #ffd343;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.mi-readmore::after {
  content: "";
  position: absolute;
  left: 0;
  top: auto;
  bottom: 0;
  height: 1px;
  width: 0;
  background: #ffd343;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.mi-readmore:hover {
  color: #ffd343;
}
.mi-readmore:hover::after {
  width: 100%;
}

/*
 * Pagination
 */
.mi-pagination ul {
  list-style: none;
  margin-top: -5px;
  margin-left: -5px;
}
.mi-pagination li {
  display: inline-block;
  list-style: none;
  margin-top: 5px;
  margin-left: 5px;
}
.mi-pagination li a {
  display: inline-block;
  border: 1px solid #ffd343;
  height: 40px;
  width: 40px;
  text-align: center;
  color: #ffd343;
  line-height: 38px;
}
.mi-pagination li.is-active a {
  background: #ffd343;
  color: #ffffff;
}

/**
 * Header Styles
 */
.mi-header {
  position: fixed;
  left: 0;
  top: 0;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  height: 100vh;
  width: 300px;
  background: #202020;
  border-right: 1px solid #2e344e;
  z-index: 10;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.mi-header-inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  overflow-y: auto;
}
.mi-header-toggler {
  position: absolute;
  left: 100%;
  top: 20px;
  padding: 0;
  height: 50px;
  width: 50px;
  text-align: center;
  font-size: 1.6rem;
  background: #202020;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
.mi-header-toggler:focus {
  border-color: #2e344e;
}
.mi-header-image {
  padding: 20px 0;
  display: block;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #2e344e;
}
.mi-header-image a {
  height: 200px;
  width: 200px;
  border-radius: 1000px;
  border: 7px solid #2e344e;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
}
.mi-header-menu {
  width: 100%;
  padding: 15px 5px;
}
.mi-header-menu li {
  list-style: none;
  text-align: center;
  display: block;
}
.mi-header-menu li:not(:last-child) {
  margin-bottom: 1px;
}
.mi-header-menu li a {
  text-transform: uppercase;
  font-size: 0.94rem;
  letter-spacing: 1px;
  display: block;
  padding: 5px 0;
  color: #a4acc4;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.mi-header-menu li a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 100%;
  height: 100%;
  width: 0;
  background: rgba(255, 211, 67, 0.2);
  visibility: hidden;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  z-index: -1;
}
.mi-header-menu li a:hover {
  color: #ffd343;
}
.mi-header-menu li a:hover::before {
  width: 100%;
  visibility: visible;
}
.mi-header-menu li a.active {
  color: #ffffff;
}
.mi-header-menu li a.active::before {
  background: #ffd343;
  width: 100%;
  visibility: visible;
}
.mi-header-copyright {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 0;
  font-size: 1rem;
  padding: 15px 0;
  border-top: 1px solid #2e344e;
}
.mi-header-copyright a {
  color: #a4acc4;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.mi-header-copyright a:hover {
  color: #ffd343;
}
.mi-header.is-visible {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .mi-header {
    width: 260px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mi-header {
    width: 260px;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  .mi-header-toggler {
    display: flex;
  }
  .mi-header-image a {
    height: 150px;
    width: 150px;
  }
  .mi-header-menu li a {
    padding: 7px 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mi-header {
    width: 250px;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  .mi-header-toggler {
    display: flex;
  }
  .mi-header-image a {
    height: 150px;
    width: 150px;
  }
  .mi-header-menu li a {
    padding: 7px 0;
  }
}
@media only screen and (max-width: 767px) {
  .mi-header {
    width: 250px;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  .mi-header-toggler {
    display: flex;
  }
  .mi-header-image a {
    height: 150px;
    width: 150px;
  }
  .mi-header-menu li a {
    padding: 7px 0;
  }
}

/*
 * Social Icons
 */
.mi-socialicons {
  padding-left: 0;
  margin-bottom: 0;
}
.mi-socialicons li {
  list-style: none;
  display: inline-block;
  margin: 0 8px;
}
.mi-socialicons li a {
  display: inline-block;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  color: #a4acc4;
  font-size: 1.5rem;
}
.mi-socialicons li a:hover {
  color: #ffd343;
}
.mi-socialicons-bordered li a {
  height: 45px;
  width: 45px;
  line-height: 1;
  text-align: center;
  border: 2px solid #2e344e;
  border-radius: 100px;
  line-height: 45px;
  font-size: 1.3rem;
}
.mi-socialicons-bordered li a svg {
  max-height: 100%;
}
.mi-socialicons-bordered li a:hover {
  border-color: #ffd343;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mi-socialicons-bordered li a {
    height: 42px;
    width: 42px;
    line-height: 42px;
    font-size: 1.1rem;
  }
}
@media only screen and (max-width: 767px) {
  .mi-socialicons li a {
    font-size: 1.25rem;
  }
  .mi-socialicons-bordered li a {
    height: 38px;
    width: 38px;
    line-height: 38px;
    font-size: 1rem;
  }
}

/*
 * Title 
 */
.mi-sectiontitle {
  margin-bottom: 60px;
  position: relative;
  z-index: 1;
}
.mi-sectiontitle span {
  position: absolute;
  left: 0;
  top: 100%;
  font-size: 6rem;
  line-height: 1;
  font-weight: 900;
  color: rgba(25, 29, 43, 0.44);
  display: inline-block;
  text-transform: uppercase;
  z-index: -1;
  -webkit-transform: translateY(-40%);
          transform: translateY(-40%);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap;
}
.mi-sectiontitle h2 {
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
  text-transform: uppercase;
}
.mi-sectiontitle h2::before {
  content: "";
  position: absolute;
  left: 0;
  top: auto;
  bottom: 0;
  height: 5px;
  width: 100px;
  background: rgba(255, 211, 67, 0.3);
  border-radius: 100px;
}
.mi-sectiontitle h2::after {
  content: "";
  position: absolute;
  left: 0;
  top: auto;
  bottom: 0;
  height: 5px;
  width: 35px;
  background: #ffd343;
  border-radius: 100px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .mi-sectiontitle span {
    font-size: 4.5rem;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mi-sectiontitle span {
    font-size: 4.5rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mi-sectiontitle {
    margin-bottom: 50px;
  }
  .mi-sectiontitle span {
    font-size: 4.2rem;
  }
}
@media only screen and (max-width: 767px) {
  .mi-sectiontitle {
    margin-bottom: 40px;
  }
  .mi-sectiontitle span {
    font-size: 3.8rem;
  }
}
@media only screen and (max-width: 575px) {
  .mi-sectiontitle span {
    font-size: 3.2rem;
  }
}

.mi-smalltitle {
  display: flex;
  align-items: center;
}
.mi-smalltitle h4 {
  font-weight: 700;
}
.mi-smalltitle-icon {
  vertical-align: middle;
  margin-right: 10px;
  font-size: 1.71rem;
  line-height: 2.43rem;
  color: #a4acc4;
}
@media only screen and (max-width: 767px) {
  .mi-smalltitle-icon {
    font-size: 1.51rem;
    line-height: 2.13rem;
  }
}

/*
 * Progress Bar
 */
.mi-progress-title {
  font-weight: 600;
  margin-bottom: 0;
}
.mi-progress-inner {
  display: flex;
  align-items: center;
}
.mi-progress-percentage {
  flex: 0 0 60px;
}
.mi-progress-container {
  background: #2e344e;
  display: block;
  height: 6px;
  width: 100%;
  position: relative;
}
.mi-progress-active {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #ffd343;
  width: 0;
  -webkit-transition: all 1s cubic-bezier(0.01, 0.57, 0.68, 1.05) 0s;
  transition: all 1s cubic-bezier(0.01, 0.57, 0.68, 1.05) 0s;
}

/**
 * Service
 */
.mi-service {
  border: 1px solid #2e344e;
  border-top: 5px solid #2e344e;
  padding: 30px;
  background: #202020;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.mi-service-icon {
  margin-bottom: 20px;
  display: inline-block;
  color: #ffd343;
  font-size: 2.5rem;
}
.mi-service h5 {
  font-weight: 600;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.mi-service h5::before {
  content: "";
  position: absolute;
  left: 0;
  top: auto;
  bottom: 0;
  height: 2px;
  width: 50px;
  background: #2e344e;
}
.mi-service p {
  margin-bottom: 0;
}
.mi-service:hover {
  border-top-color: #ffd343;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .mi-service {
    padding: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .mi-service {
    padding: 20px;
  }
}

/*
 * Portfolio
 */
.mi-portfolio {
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  visibility: hidden;
  opacity: 0;
}
.mi-portfolio-image {
  display: block;
  position: relative;
}
.mi-portfolio-image::before {
  content: "";
  position: absolute;
  left: 15px;
  top: 15px;
  height: calc(100% - 30px);
  width: calc(100% - 30px);
  background: rgba(255, 211, 67, 0.3);
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: 0;
          transform-origin: 0;
}
.mi-portfolio-image ul {
  margin-bottom: 0;
  padding-left: 0;
  position: absolute;
  left: 0;
  top: 50%;
  text-align: center;
  width: 100%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  visibility: hidden;
  opacity: 0;
  margin-top: 30px;
  -webkit-transition: all 0.4s ease-in-out 0.2s;
  transition: all 0.4s ease-in-out 0.2s;
}
.mi-portfolio-image ul li {
  list-style: none;
  display: inline-block;
  margin: 0 7px;
}
.mi-portfolio-image ul li a, .mi-portfolio-image ul li button {
  display: inline-block;
  height: 45px;
  width: 45px;
  padding: 5px 0;
  vertical-align: middle;
  text-align: center;
  line-height: 1;
  background: #a4acc4;
  color: #ffffff;
  border: 1px solid #a4acc4;
  border-radius: 100px;
  outline: none;
  font-size: 1.25rem;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.mi-portfolio-image ul li a svg, .mi-portfolio-image ul li button svg {
  height: 100%;
}
.mi-portfolio-image ul li a:hover, .mi-portfolio-image ul li button:hover {
  background: #ffd343;
  border-color: #ffd343;
}
.mi-portfolio h5 {
  color: #ffffff;
  margin-top: 15px;
  margin-bottom: 0;
  font-weight: 700;
}
.mi-portfolio h5 a {
  color: #ffffff;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.mi-portfolio h5 a:hover {
  color: #ffd343;
}
.mi-portfolio h6 {
  color: #a4acc4;
  margin-bottom: 0;
}
.mi-portfolio:hover .mi-portfolio-image {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.mi-portfolio:hover .mi-portfolio-image::before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
.mi-portfolio:hover .mi-portfolio-image ul {
  visibility: visible;
  opacity: 1;
  margin-top: 0;
}
.mi-portfolio.mi-portfolio-visible {
  visibility: visible;
  opacity: 1;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row_view_card_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

/*
 * Resume 
 */
.mi-resume-wrapper {
  border-left: 3px solid #2e344e;
}
.mi-resume {
  display: flex;
}
.mi-resume-summary {
  align-self: flex-start;
  flex: 0 0 220px;
  max-width: 220px;
  padding-left: 20px;
  position: relative;
}
.mi-resume-summary::before {
  content: "";
  position: absolute;
  left: -9px;
  top: 6px;
  height: 15px;
  width: 15px;
  border-radius: 100px;
  background: rgb(19, 19, 19);
  border: 3px solid #2e344e;
}
.mi-resume-year {
  color: #a4acc4;
  margin-bottom: 0;
}
.mi-resume-company {
  color: #ffffff;
}
.mi-resume-skillsGained {
  margin-top: 10px;
  font-size: 17px;
  color: #b1f7b1;
}
.mi-resume-toolsGained {
  margin-top: 10px;
  font-size: 15px;
  color: #b1f7b1;
}
.mi-resume-details {
  position: relative;
  padding-left: 50px;
}
.mi-resume-details::before {
  content: "";
  position: absolute;
  left: 0;
  top: 15px;
  height: 1px;
  width: 30px;
  background: #2e344e;
}
.mi-resume-details h5 {
  color: #ffd343;
  margin-bottom: 0;
  font-weight: 700;
}
.mi-resume-details p {
  margin-bottom: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mi-resume-summary {
    flex: 0 0 170px;
    max-width: 170px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mi-resume-summary {
    flex: 0 0 170px;
    max-width: 170px;
  }
}
@media only screen and (max-width: 767px) {
  .mi-resume {
    flex-wrap: wrap;
  }
  .mi-resume-details {
    padding-left: 20px;
  }
  .mi-resume-details::before {
    left: 3px;
    top: -15px;
    width: 12px;
  }
}

/**
 * Service
 */
.mi-testimonial-slider .slick-list {
  margin-left: -15px;
  margin-right: -15px;
}
.mi-testimonial {
  margin: 0 15px;
}
.mi-testimonial-content {
  min-height: 150px;
  display: flex;
  align-items: center;
  padding: 30px;
  margin-bottom: 35px;
  position: relative;
  border-left: 5px solid #2e344e;
  background: #202020;
}
.mi-testimonial-content::after {
  content: "";
  position: absolute;
  left: 30px;
  top: 100%;
  border-style: solid;
  border-width: 12px;
  border-color: #202020 transparent transparent #202020;
}
.mi-testimonial-content p {
  margin-bottom: 0;
  font-size: 1.2rem;
}
.mi-testimonial-author h5 {
  margin-bottom: 0;
}
.mi-testimonial-author h6 {
  margin-bottom: 0;
  color: #a4acc4;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .mi-testimonial-content {
    padding: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mi-testimonial-content p {
    font-size: 1.1rem;
  }
}
@media only screen and (max-width: 767px) {
  .mi-testimonial-content p {
    font-size: 1.1rem;
  }
}
@media only screen and (max-width: 575px) {
  .mi-testimonial-content {
    padding: 20px;
  }
}

/**
 * Form Styles
 */
.mi-form-field {
  margin-top: 30px;
  position: relative;
}
.mi-form-field label {
  position: absolute;
  left: 15px;
  top: -13px;
  background: rgb(19, 19, 19);
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  pointer-events: none;
  padding: 0 10px;
  font-size: 0.94rem;
}
.mi-form-field input, .mi-form-field textarea {
  border: 1px solid #2e344e;
  font-size: 1rem;
}

/**
 * Blog Styels
 */
.mi-blog {
  background: #202020;
  padding: 15px;
}
.mi-blog-image {
  position: relative;
  overflow: hidden;
}
.mi-blog-image img {
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.mi-blog-date {
  position: absolute;
  left: 20px;
  top: 20px;
  background: rgba(255, 211, 67, 0.8);
  color: #ffffff;
  padding: 10px;
  text-align: center;
  min-width: 80px;
  min-height: 80px;
}
.mi-blog-date span {
  display: block;
}
.mi-blog-date .date {
  font-size: 2.5rem;
  line-height: 1;
  font-weight: 700;
}
.mi-blog-date .month {
  font-size: 1.3rem;
  line-height: 1;
  font-weight: 700;
}
.mi-blog-content {
  padding-top: 15px;
}
.mi-blog-content h5 {
  margin-bottom: 0;
  font-weight: 600;
  margin-bottom: 0;
}
.mi-blog-content h5 a {
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  color: #ffffff;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mi-blog-content h5 a:hover {
  color: #ffd343;
}
.mi-blog:hover .mi-blog-image img {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
}

/*
 * Blog Details
 */
.mi-blog-details img {
  margin-bottom: 15px;
}
.mi-blog-details h3 {
  font-weight: 700;
}
.mi-blog-details blockquote {
  margin-top: 25px;
  margin-bottom: 25px;
}

.notfound {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
}
.notfound-inner {
  background: #202020;
  padding: 100px 50px;
  width: 100%;
}
.notfound h1 {
  margin-top: -20px;
  font-size: 12.5rem;
  line-height: 1;
  font-weight: 700;
  color: #fd7171;
}
.notfound h3 {
  margin-top: -10px;
}
.notfound .mi-button {
  margin-top: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .notfound h1 {
    font-size: 10.5rem;
  }
}
@media only screen and (max-width: 767px) {
  .notfound-inner {
    padding: 75px 15px;
  }
  .notfound h1 {
    margin-top: -15px;
    font-size: 8.5rem;
  }
}
@media only screen and (max-width: 767px) {
  .notfound-inner {
    padding: 65px 15px;
  }
  .notfound h1 {
    font-size: 7.5rem;
  }
}

.media {
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 5px;
  margin: 5px 0;
  background-color: rgb(75, 70, 70);
  border-radius: 10px;
  position: relative;
  font-family: "Montserrat", sans-serif;
}

.media:hover {
  background-color: white;
  color: black;
}

@media (max-width: 550px) {
  .media {
    width: 46%;
  }
}
.poster {
  border-radius: 10px;
}

.title {
  width: 100%;
  text-align: center;
  font-size: 17px;
  padding: 4px 0;
}

.bookCover {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.articles-cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.ArticleCard_item {
  display: flex;
  padding: 1rem;
  box-shadow: 0 1px 8px 12px rgba(104, 174, 167, 0.2), 0 6px 1px 1px rgba(139, 137, 65, 0.19);
}

.ArticleCard {
  border-radius: 20px;
  overflow: hidden;
  max-width: 30ch;
  background-color: rgba(144, 161, 137, 0.3803921569);
  max-height: auto;
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
  flex-direction: column;
}
.ArticleCard:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.ArticleCard:hover .ArticleCardContent-title::after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.ArticleCardContent {
  padding: 1.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.ArticleCardContent-title {
  position: relative;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
}
.ArticleCardContent-title::after {
  content: "";
  position: absolute;
  height: 3px;
  left: 0;
  bottom: 0;
  width: 100%;
  background: yellow;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: left;
          transform-origin: left;
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
}
.ArticleCardContent-button {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  color: #ffd343;
  padding: 0.5em 1.25em;
  border-radius: 2em;
}
.ArticleCardContent-button:hover {
  background-color: #ffd343;
  color: white;
}
.ArticleCardContent-description {
  padding-top: 2em;
  padding-bottom: 1em;
}
.ArticleCardContent-tags {
  padding-bottom: 1em;
  display: inline-block;
}

.ArticleImageContainer {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 200px;
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 300px;
  white-space: nowrap;
}

.skillTag {
  background-color: #ffd343;
  border-radius: 30px;
  border-color: white;
  border-width: 1.3px;
  border-style: solid;
  font-weight: bold;
  color: white;
  box-shadow: -2px -2px 1px 3px #ffd343 inset, 5px 5px 5px rgba(255, 255, 255, 0.5) inset;
  margin-left: 10px;
  font-size: 13px;
  margin-bottom: 5px;
  width: 39%;
  text-align: center;
}

.mi-quotes {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.mi-quotes .mi-button {
  padding: 0px 40px;
  background-color: #4a90e2;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.mi-quotes .mi-button:hover {
  background-color: #2275d7;
}
.mi-quotes .mi-button:focus {
  outline: none;
}

.modal-container {
  background-size: cover;
  font-family: "Times New Roman", serif;
  /* Replace with a more suitable font if you have one */
  color: #543D1B;
  /* A color that represents ink */
  padding: 30px;
  /* Adjust based on your design needs */
  border-radius: 5px;
  /* Optional: if you want rounded corners */
}

.quotes-container {
  padding-left: 50px;
  padding-right: 50px;
  align-items: center;
  padding-top: 20%;
  display: flex;
  flex-direction: column;
  grid-gap: 10%;
  gap: 10%;
}
.quotes-container .text-color {
  color: #0c0e16;
}
.quotes-container .text-color-greek {
  color: #0c0e16;
  font-size: x-large;
}
.quotes-container .quotes-text-container {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  padding-top: 20px;
}
.quotes-container .quotes-text-container p {
  word-break: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

.greek-words-container {
  background-color: #00c300;
  max-height: 50%;
}

.divider {
  border: 0;
  height: 2px;
  background-color: #001489;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 15%;
  margin-right: 15%;
}

.dimmed-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  /* Adjust the alpha for darkness */
  z-index: 10;
  /* Ensure it's below the button */
}

.highlight-button {
  z-index: 11 !important;
  /* Above the dimmed overlay */
  position: relative;
  /* Adjust as needed based on your layout */
  box-shadow: 0 0 30px 15px rgb(255, 255, 255);
  /* More pronounced glow */
  border: none;
  /* Optional: removes border */
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  /* Smooth transition for the glow effect */
  -webkit-animation: pulse 1.5s infinite;
          animation: pulse 1.5s infinite;
}

.tooltip-text {
  -webkit-animation: pulse 1s infinite !important;
          animation: pulse 1s infinite !important;
}

.dimmed-background {
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes popEffect {
  0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes popEffect {
  0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes pulse {
  0%, 100% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes pulse {
  0%, 100% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
/*
 * Home Styles
 */
.mi-home-area {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
  z-index: 1;
}
.mi-home-particle {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.mi-home-content {
  text-align: center;
  padding: 50px 80px;
}
.mi-home-content h1 {
  font-weight: 700;
}
.mi-home-content p {
  margin-top: 15px;
  font-size: 1.25rem;
  line-height: 2rem;
}
.mi-home-content .mi-socialicons {
  margin-top: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .mi-home-content {
    padding: 50px 0px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mi-home-content {
    padding: 50px 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mi-home-content {
    padding: 50px 0px;
  }
  .mi-home-content p {
    font-size: 1.15rem;
    line-height: 1.75rem;
  }
}
@media only screen and (max-width: 767px) {
  .mi-home-content {
    padding: 50px 0px;
  }
  .mi-home-content p {
    font-size: 1.08rem;
    line-height: 1.55rem;
  }
}
@media only screen and (max-width: 575px) {
  .mi-home-content h1 span {
    display: block;
  }
}

/*
 * About Styles
 */
.mi-about-image {
  position: relative;
}
.mi-about-image::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 65%;
  width: 15px;
  background: rgba(255, 211, 67, 0.6);
}
.mi-about-image::after {
  content: "";
  position: absolute;
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
  height: 65%;
  width: 15px;
  background: rgba(255, 211, 67, 0.6);
}
.mi-about-image img {
  width: 100%;
}
.mi-about-image-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100px;
  width: 100px;
  margin-left: -50px;
  margin-top: -50px;
  color: #ffffff;
  display: inline-block;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  pointer-events: none;
}
.mi-about-image-icon svg {
  height: 100%;
  width: 100%;
}
.mi-about-image:hover .mi-about-image-icon {
  visibility: visible;
  opacity: 0.5;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.mi-about-content h3 {
  font-weight: 600;
  margin-top: -8px;
}
.mi-about-content ul li {
  list-style: none;
  display: flex;
}
.mi-about-content ul li:not(:last-child) {
  margin-bottom: 3px;
}
.mi-about-content ul li b {
  min-width: 120px;
  display: inline-block;
  position: relative;
  margin-right: 7px;
}
.mi-about-content ul li b:after {
  content: ":";
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
}
.mi-about-content .mi-button {
  margin-top: 15px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mi-about-content {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .mi-about-image-icon {
    height: 70px;
    width: 70px;
    margin-left: -35px;
    margin-top: -35px;
  }
  .mi-about-content {
    margin-top: 30px;
  }
  .mi-about-content ul li b {
    min-width: 90px;
  }
}

/**
 * Contact
 */
.mi-contact-info {
  margin-top: -30px;
}
.mi-contact-infoblock {
  margin-top: 30px;
  background: #202020;
  padding: 30px;
  display: flex;
}
.mi-contact-infoblock-icon {
  display: inline-block;
  height: 70px;
  width: 70px;
  flex: 0 0 70px;
  max-width: 70px;
  border: 1px solid #2e344e;
  text-align: center;
  line-height: 66px;
  margin-right: 20px;
}
.mi-contact-infoblock-icon svg {
  height: 30px;
  width: 30px;
}
.mi-contact-infoblock-content h6 {
  margin-top: -5px;
}
.mi-contact-infoblock-content p {
  margin-bottom: 0;
}
.mi-contact-infoblock-content p a {
  color: #a4acc4;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.mi-contact-infoblock-content p a:hover {
  color: #ffd343;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mi-contact-info {
    margin-top: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .mi-contact-info {
    margin-top: 50px;
  }
}
@media only screen and (max-width: 575px) {
  .mi-contact-infoblock {
    padding: 20px;
    flex-wrap: wrap;
  }
  .mi-contact-infoblock-icon {
    height: 55px;
    width: 55px;
    flex: 0 0 55px;
    max-width: 55px;
    line-height: 51px;
  }
  .mi-contact-infoblock-content {
    margin-top: 20px;
    flex: 0 0 100%;
  }
}
