.mi-quotes {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; // Adjust as needed'

  .mi-button {
    padding: 0px 40px;
    background-color: #4a90e2; // Example blue color, change as needed
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: darken(#4a90e2, 10%); // Slightly darken the button on hover
    }

    &:focus {
      outline: none; // Remove the focus outline
    }
  }
}

.modal-container {
  background-size: cover;
  font-family: 'Times New Roman', serif; /* Replace with a more suitable font if you have one */
  color: #543D1B; /* A color that represents ink */
  padding: 30px; /* Adjust based on your design needs */
  border-radius: 5px; /* Optional: if you want rounded corners */

}

.quotes-container {
  padding-left: 50px;
  padding-right: 50px;
  align-items: center;
  padding-top: 20%;
  display: flex;
  flex-direction: column;
  gap: 10%;

  .text-color {
    color: #0c0e16;
  }

  .text-color-greek {
    color: #0c0e16;
    font-size: x-large;
  }

  .quotes-text-container {
    max-width: 600px; // Set a max-width to prevent the text from being too wide
    margin: 0 auto; // Center it
    text-align: center; // Center the text if desired
    padding-top: 20px;


    p {
      word-break: break-word; // Prevents overflow by breaking long words
      overflow-wrap: break-word; // Ensures long words can break and wrap onto the next line
      hyphens: auto; // Automatically add hyphens when breaking words (depends on language support)
    }
  }
}

.greek-words-container {
  background-color: #00c300;
  max-height: 50%;
}

.divider {
  border: 0; // Remove default border
  height: 2px; // Set the height of the divider line
  background-color: #001489; // Set the color of the divider line
  margin-top: 20px; // Space above the divider
  margin-bottom: 20px; // Space below the divider
  margin-left: 15%;
  margin-right: 15%;
}

.dimmed-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.80); /* Adjust the alpha for darkness */
  z-index: 10; /* Ensure it's below the button */
}

.highlight-button {
  z-index: 11 !important; /* Above the dimmed overlay */
  position: relative; /* Adjust as needed based on your layout */
  box-shadow: 0 0 30px 15px rgba(255, 255, 255, 1); /* More pronounced glow */
  border: none; /* Optional: removes border */
  transition: all 0.3s ease; /* Smooth transition for the glow effect */
  animation: pulse 1.5s infinite
}

.tooltip-text {
  animation: pulse 1s infinite !important;
}

.dimmed-background {
  // ... existing styles ...
  transition: opacity 0.5s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes popEffect {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulse {
  0%, 100% {
    transform: scale(.9);
  }
  50% {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

